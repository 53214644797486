<!--资料上传未通过页面-->
<template>
  <div class="box">
    <div class="nav_top">
      <div class="top"><top-nav></top-nav></div>
      <!-- <h1>环保装备交易平台</h1>
      <h2>服务环境产业上下游</h2> -->
    </div>
    <div class="content">
      <div class="left">
        <img src="../assets/fail.png" width="100%" height="100%" />
      </div>
      <div class="right">
        <div class="title">
          抱歉您的供应商资料暂未审核通过，还不能查看上传的资料
        </div>
        <div class="btn">重新填写</div>
      </div>
    </div>
    <div class="content5">
      <bottom></bottom>
    </div>
  </div>
</template>

<script>
import topNav from "../components/topNav";
import bottom from "../components/bottom";

export default {
  components: {
    topNav,
    bottom,
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.box {
  width: 100%;
  .nav_top {
    width: 100%;
    height: 540px;
    background-image: url("../assets/banner_mao.png");
    background-repeat: no-repeat;
    background-size: cover;
    box-sizing: border-box;
    .top {
      width: 100%;
      height: 100px;
      background-size: 100% 100px;
      background-repeat: no-repeat;
    }
    h1 {
      font-size: 66px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #ffffff;
      text-align: center;
      margin-top: 140px;
    }
    h2 {
      font-size: 40px;
      font-family: PingFang SC;
      font-weight: bold;
      color: rgba($color: #ffffff, $alpha: 0.8);
      text-align: center;
      margin-top: 51px;
    }
  }
  .content {
    margin: 65%;
    margin: 0 auto;
    width: 1200px;
    height: 150px;
    background: #f0f6ff;
    border: 1px solid #2477e4;
    margin-top: 184px;
    margin-bottom: 430px;
    display: flex;
    padding-left: 52px;
    padding-top: 30px;
    .left {
      width: 46px;
      height: 46px;
      margin-right: 30px;
    }
    .right {
      .title {
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
      }
      .btn {
        width: 100px;
        height: 34px;
        background: #2477e4;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        text-align: center;
        line-height: 34px;
        margin-top: 20px;
      }
    }
  }
}
</style>
